import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {ComponentCanDeactivate} from './component-can-deactivate';
import {NbDialogService} from '@nebular/theme';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog/confirm-dialog.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private dialogService: NbDialogService) {
  }

  canDeactivate(component: ComponentCanDeactivate): Promise<any> {
    if (!component.canDeactivate()) {
      const dialog = this.dialogService.open(ConfirmDialogComponent, {
        autoFocus: false,
        closeOnEsc: true,
        hasBackdrop: true,
        closeOnBackdropClick: true,
        hasScroll: false,
        context: {
          title: `Unsaved changes`,
          content: 'Exit without saving?',
          type: 'warning',
          btnYesText: 'Yes',
          btnCancelText: 'No'
        },
      });

      return new Promise(
        (resolve, reject) => {
          dialog.onClose.subscribe(res => {
            resolve(res);
          });
        }
      );
    }
    return new Promise(
      (resolve, reject) => {
        resolve(true);
      }
    );
  }
}
