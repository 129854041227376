import {EventEmitter, Injectable} from '@angular/core';
import {ProductionService} from '../production.service';
import { Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';
import {CommunicationService} from '../communication/communication.service';
import {ShowcaseDialogComponent} from '../dialogs/showcase-dialog/showcase-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {DatesPreviewDialogComponent} from '../dialogs/dates-preview-dialog/dates-preview-dialog.component';
import {API} from '../communication/API';
import {Observable} from 'rxjs';
import { Show } from '../../../models/show';
import { UpdateDatesFormGroupInterface } from '../dialogs/update-dates-dialog/update-dates-dialog.component';

interface CustomDateObject {
  name: string;
  city: string;
  venue: string;
  eventDateLocal: string;
  eventDateUTC: string;
  timezone: string;
}

@Injectable({
  providedIn: 'root'
})
export class DatesShowService {
  public datesWereChanged: EventEmitter<any> = new EventEmitter();
  private selectedProduction: Show;
  private dates: string[] = [];
  private futureDates: string[] = [];
  private pastDates: string[] = [];
  private timeZone: string;
  private localFutureDates: Moment[] = [];
  private localPastDates: Moment[] = [];
  private backupDates: string[] = [];
  private nowDate: Moment = moment();
  private showId: string;
  private STUBHUB = 'StubHub';
  private TICKET_MASTER = 'TicketMaster';

  constructor(private productionService: ProductionService,
              private communicationService: CommunicationService,
              private dialogService: NbDialogService) {
    this.updateService();
  }

  getFutureSessions(cellDate: Date): string[] {
    return this.getSessions(cellDate, this.localFutureDates);
  }

  getPastSessions(cellDate: Date): string[] {
    return this.getSessions(cellDate, this.localPastDates);
  }

  private getSessions(cellDate: Date, fromDates: Moment[]): string[] {
    const arrayResult: string[] = [];
    const cellDateMoment = moment(cellDate).format('YYYY-MM-DD');
    const array = fromDates.filter((d: any) => {
      const t = moment(d).format('YYYY-MM-DD');
      // if (t.isSameOrAfter(cellDateStr)) {
      //   // console.log(cellDateStr.isSameOrAfter(t));
      //   return t.isSame(cellDateStr, 'days');
      // }else {
      //   return false;
      // }
      return t.includes(cellDateMoment);
      // return t.isSame(cellDateMoment, 'days');
    });
    array.forEach((e) => {
      arrayResult.push(moment(e).format('LT'));
    });
    return arrayResult;
  }

  updateDates(url, searchQuery: UpdateDatesFormGroupInterface) {
    const result: CustomDateObject[] = [];
    const $updateObservable = new Observable((subscriber => {
      if (url === this.STUBHUB) {
        const stubHubRequest = this.communicationService.stubHubCall(searchQuery).subscribe(
          (res: any) => {
            if (res && res.events && res.events.length) {
              const resultEvents = res.events.forEach(event => {
                const stubHubDate: CustomDateObject = {
                  name: event.name || '',
                  city: event.venue.city || '',
                  venue: event.venue.name || '',
                  eventDateLocal: event.eventDateLocal || '',
                  eventDateUTC: event.eventDateUTC || '',
                  timezone: event.timezone || ''
                };
                result.push(stubHubDate);
              });
              const datesViewObject = this.createListOfDates(searchQuery, result);
              this.previewListOfDates(datesViewObject.datesView, datesViewObject.result);
              subscriber.next(true);
            } else {
              this.warn('No results', 'Nothing was found');
              subscriber.next(false);
            }
            stubHubRequest.unsubscribe();
          },
          (error) => {
            this.warn('Attention', 'Something went wrong');
            subscriber.next(false);
            stubHubRequest.unsubscribe();
          });
      } else if (url === this.TICKET_MASTER) {
        const ticketMasterRequest = this.communicationService.ticketMasterCall(searchQuery).subscribe(
          (res) => {
            if (res && res['_embedded'] && res['_embedded'].events && res['_embedded'].events.length) {
              const resultEvents = res['_embedded'].events.forEach((event) => {
                const date = event.dates.start.noSpecificTime ? null : event.dates.start.dateTime && event.dates.start.dateTime.length && event.dates.start.dateTime;
                const eventDateLocal = moment(date).tz(this.timeZone).format();
                let city;
                let venueName = event._embedded && event._embedded.venues && event._embedded.venues.length && event._embedded.venues[0].name;
                if (!venueName) {
                  city = event._embedded && event._embedded.venues && event._embedded.venues.length && event._embedded.venues[0].city && event._embedded.venues[0].city.name;
                  venueName = city ? `City: ${city}` : 'No venue name or city name were found';
                }
                const ticketMasterDate: CustomDateObject = {
                  name: event.name || '',
                  city: city || '',
                  venue: venueName || '',
                  eventDateLocal: eventDateLocal || '',
                  eventDateUTC: date || '',
                  timezone: event.timezone || ''
                };
                result.push(ticketMasterDate);
              });
              const datesViewObject = this.createListOfDates(searchQuery, result);
              this.previewListOfDates(datesViewObject.datesView, datesViewObject.result);
              subscriber.next(true);
            } else {
              this.warn('0 results', 'Nothing were found');
              subscriber.next(false);
            }
            ticketMasterRequest.unsubscribe();
          },
          (error) => {
            this.warn('Attention', 'Something went wrong');
            subscriber.next(false);
            ticketMasterRequest.unsubscribe();
          });
      }
    }));
    return $updateObservable;
  }

  private previewListOfDates(datesView: string, result: CustomDateObject[]) {
    const dialog = this.dialogService.open(DatesPreviewDialogComponent, {
      autoFocus: false,
      closeOnEsc: true,
      hasBackdrop: true,
      closeOnBackdropClick: true,
      hasScroll: false,
      context: {
        title: `${result.length} results were found`,
        content: datesView,
        btnConfirmText: 'Import',
        btnCancelText: 'Cancel',
      },
    });

    dialog.onClose.subscribe((response) => {
      if (response) {
        this.localFutureDates = [];
        this.futureDates = [];
        this.localPastDates = [];
        this.pastDates = [];
        result.forEach((e) => {
          const d = moment(e.eventDateUTC).tz(this.timeZone);
          if (d.isSameOrAfter(this.nowDate)) {
            this.futureDates.push(moment(e.eventDateUTC).toISOString());
          } else {
            this.pastDates.push(moment(e.eventDateUTC).toISOString());
          }
        });
        this.convertDatesToLocal();
        this.sendReloadOrChangesEmit(true, true);
      } else {
        this.sendReloadOrChangesEmit(false, false);
      }
    });
  }

  convertDatesToLocal() {
    this.localFutureDates = [];
    this.localPastDates = [];
    if (this.selectedProduction && this.selectedProduction.dates && this.dates.length === 0 && this.futureDates.length === 0) {
      this.dates = this.selectedProduction.dates;
      this.timeZone = this.productionService.getProductionTimeZone();
      this.separateDates();
    }
    this.futureDates.sort((a, b) => {
      return moment(a).diff(moment(b));
    });
    this.futureDates.forEach((e) => {
      const d = moment(e).tz(this.timeZone);
      this.localFutureDates.push(d);
    });
    this.pastDates.sort((a, b) => {
      return moment(a).diff(moment(b));
    });
    this.pastDates.forEach((e) => {
      const d = moment(e).tz(this.timeZone);
      this.localPastDates.push(d);
    });
  }

  revertChanges() {
    this.futureDates = this.backupDates.slice();
    this.convertDatesToLocal();
    this.sendReloadOrChangesEmit(true, false);
  }

  addDate(date: string): Observable<boolean> {
    const $addObservable = new Observable<boolean>(subscriber => {
      const index = this.isFutureDateAlreadyExists(date);
      if (moment(date).isSameOrAfter(this.nowDate)) {
        if (index < 0) {
          this.futureDates.push(date);
          this.convertDatesToLocal();
          this.sendReloadOrChangesEmit(false, true);
          subscriber.next(true);
        } else {
          subscriber.next(false);
          this.warn('Attention', 'The date already exists');
        }
      } else {
        subscriber.next(false);
        this.warn('Attention', 'The date you entered has past');
      }
    });
    return $addObservable;
  }

  editDate(newDate: string, oldDate: string): Observable<boolean> {
    const $editObservable = new Observable<boolean>((subscriber => {
      const oldIndex = this.isFutureDateAlreadyExists(oldDate);
      const newIndex = this.isFutureDateAlreadyExists(newDate);
      if (oldIndex >= 0 && newIndex < 0) {
        this.futureDates.splice(oldIndex, 1, newDate);
        this.convertDatesToLocal();
        this.sendReloadOrChangesEmit(false, true);
        subscriber.next(true);
      } else {
        subscriber.next(false);
        this.warn('Attention', 'The date already exists');
      }
    }));
    return $editObservable;
  }

  removeDate(date: string): Observable<boolean> {
    const $removeObservable = new Observable<boolean>((subscriber => {
      const index = this.isFutureDateAlreadyExists(date);
      if (index >= 0) {
        this.futureDates.splice(index, 1);
        this.convertDatesToLocal();
        this.sendReloadOrChangesEmit(false, true);
        subscriber.next(true);
      } else {
        subscriber.next(false);
      }
    }));
    return $removeObservable;
  }

  private sendReloadOrChangesEmit(isReload, isChanges) {
    this.productionService.setProduction(this.selectedProduction).then((resultation) => {
      this.datesWereChanged.emit({isReload, isChanges});
    });
  }

  updateService() {
    this.dates = [];
    this.futureDates = [];
    this.pastDates = [];
    this.selectedProduction = this.productionService.getProduction();
    // console.log(this.selectedProduction);
    if (this.selectedProduction) {
      this.showId = this.selectedProduction._id;
      this.convertDatesToLocal();
      this.backupDates = this.futureDates.slice();
    }
  }

  warn(title, message) {
    return this.dialogService.open(ShowcaseDialogComponent, {
      autoFocus: false,
      closeOnEsc: true,
      hasBackdrop: true,
      closeOnBackdropClick: true,
      context: {
        title: title,
        type: 'warning',
        content: message,
        btnText: 'Ok'
      },
    });
  }

  private isFutureDateAlreadyExists(date: string) {
    return this.futureDates.findIndex(element => moment(element).isSame(date));
  }

  separateDates() {
    this.futureDates = [];
    this.pastDates = [];
    this.dates.forEach((e) => {
      const d = moment(e).tz(this.timeZone);
      if (d.isSameOrAfter(this.nowDate)) {
        this.futureDates.push(d.toISOString());
        return d.isSameOrAfter(this.nowDate);
      } else {
        this.pastDates.push(d.toISOString());
        return d.isSameOrAfter(this.nowDate);
      }
    });
  }

  saveChanges() {
    const newDates = this.pastDates.concat(this.futureDates);
    this.selectedProduction.dates = newDates;
    this.productionService.setProduction(this.selectedProduction);
    this.communicationService.putHttp(this.selectedProduction, API.show(this.showId)).subscribe(
      (res) => {
        this.warn('Data was updated', 'The changes will be updated tomorrow');
      }, (error) => {
        this.warn('Attention', 'Something went wrong');
      });
    this.sendReloadOrChangesEmit(false, false);
  }

  createListOfDates(searchQuery: UpdateDatesFormGroupInterface, result: CustomDateObject[]) {
    if (searchQuery.notInclude) {
      result = result.filter((e) => {
        return !e.name.toLowerCase().includes(searchQuery.notInclude.toLowerCase());
      });
    }
    let datesView = '<ul class="upcoming-dates" >';
    result.forEach(
      (date) => {
        const dateParse = moment(date.eventDateLocal).tz(this.timeZone).format('DD MMM YYYY LT z');
        datesView += `<li>${date.name} (${date.venue}, ${date.city}) - ${dateParse}</li><hr>`;
      });
    return {datesView, result};
  }
}
