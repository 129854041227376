import { environment } from "../../../../environments/environment";

export const SOURCE_URL = environment.sourceUrl
export const BASE_URL = environment.baseUrl;

export const LOGIN_URL = environment.loginUrl;
export const FORGOT_PASSWORD_URL = environment.forgotPasswordUrl;
const STUB_TOKEN = environment.stubToken;

export const API = {
  admin: BASE_URL + 'admin',
  venues: BASE_URL + 'admin/venues',
  venueEdit: BASE_URL + 'admin/venues/edit',
  venueNew: BASE_URL + 'admin/venues',
  show: (id) => `${BASE_URL}admin/shows/${id}`,
  shows: BASE_URL + 'admin/shows',
  showEdit: (id) => BASE_URL + 'admin/shows/' + id,
  showNew: BASE_URL + 'admin/shows',
  subs: (id) => BASE_URL + 'admin/subs/show/' + id,
  subsEdit: (id) => BASE_URL + 'admin/subs/edits/' + id,
  subsEdits: BASE_URL + 'admin/subs/edits',
  updateServices: BASE_URL + 'update-services/true',

  TICKET_MASTER_API_URL: 'https://app.ticketmaster.com/discovery/v2/events.json?apikey=05Jw2dAbsGsemr89YIlZDhWL2GJxBCSE&includeTBA=yes&includeTBD=yes&includeFamily=yes&size=199',
  STUB_API_URL: 'https://api.stubhub.com/sellers/search/events/v3?rows=500&sort=eventDateUTC&parking=false asc',
  STUB_HEADERS: {Authorization: `Bearer ` + STUB_TOKEN, Accept: `application/json`},

  subsEditsSave: BASE_URL + 'admin/subs/subsChange',
  subsEditsGetById: (id) => BASE_URL + 'admin/subs/subsChange/show/' + id,
  subsEditsGetAll: BASE_URL + 'admin/subs/subsChange',
  subsEditsGetHistory: BASE_URL + 'admin/subs/subsChange/true',
  subsChangesUpdate: BASE_URL + 'admin/subs/subsChange/',
  subsUploadFile: BASE_URL + 'admin/subs/uploadSubsFile',
  subsUpdateMode: BASE_URL + 'admin/subs/updateSubsMode',
  subsTransfer: BASE_URL + 'admin/subs/subsTransfer',
  sendEmails: BASE_URL + 'admin/email/sendEmail',

  subsEditsUpdate: (id) => BASE_URL + 'admin/subs/edits/' + id, // POST updates/creates modified subs.
  subsEditsDelete: BASE_URL + 'admin/subs/modified/delete/',
  subsEditsStatus: (id) => BASE_URL + 'admin/subs/edits-status/' + id, // POST
  subsEditsAvailable: (id) => BASE_URL + 'admin/subs/edits-available/' + id, // POST updates/creates modified subs.
  updateShow: (id) => BASE_URL + 'admin/shows/' + id,
  register: BASE_URL + 'admin/register',
  languages: BASE_URL + 'admin/languages',
  types: BASE_URL + 'admin/shows-types',
  email: BASE_URL + 'admin/email/send/',
  proofingResults: BASE_URL + 'admin/proofing',
  cities: BASE_URL + 'admin/cities',
  citiesEdit: BASE_URL + 'admin/cities/edit',
  citiesDelete: BASE_URL + 'admin/cities/delete',
  dbSync: BASE_URL + 'update-services/true',
  addComments: BASE_URL + 'admin/shows/addComments',
  getShowComments: (showId) => BASE_URL + 'admin/shows/addComments/' + showId,
  prices: BASE_URL + 'admin/prices',

  getAllUsers: BASE_URL + 'admin/all-admins',
  createNewAdminUser: BASE_URL + 'admin/register',
  updateAdminUser: BASE_URL + 'admin/update',
  deleteAdminUser: BASE_URL + 'admin/delete',
};
