import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-dates-preview-dialog',
  templateUrl: './dates-preview-dialog.component.html',
  styleUrls: ['./dates-preview-dialog.component.scss']
})
export class DatesPreviewDialogComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;
  @Input() content: string;
  @Input() btnConfirmText: string;
  @Input() btnCancelText: string;

  constructor(protected ref: NbDialogRef<DatesPreviewDialogComponent>) { }

  ngOnInit() {
  }

  onConfirm() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }
}
