import { Component, OnInit } from '@angular/core';
import { NbLoginComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class NgxLoginComponent extends NbLoginComponent {
  // NOTE: Had to take the pattern attribute out of the html for the username input element.
  // It was a dead giveaway the field is an email. Original pattern below:
  // <!-- pattern=".+@.+\..+" -->
}
