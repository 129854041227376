import {APP_BASE_HREF} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CoreModule} from './@core/core.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ThemeModule} from './@theme/theme.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard} from './@core/utils/auth-guard.service';
import {NbAccordionModule, NbDatepickerModule, NbDialogModule} from '@nebular/theme';
import {CanDeactivateGuard} from './@core/utils/can-deactivate/can-deactivate.guard';
import {ShowcaseDialogComponent} from './@core/utils/dialogs/showcase-dialog/showcase-dialog.component';
import {ConfirmDialogComponent} from './@core/utils/dialogs/confirm-dialog/confirm-dialog.component';
import {AppInterceptor} from './@core/interceptor/app-interceptor';
import {UiSwitchModule} from 'ngx-ui-switch';
import {UpdateDatesDialogComponent} from './@core/utils/dialogs/update-dates-dialog/update-dates-dialog.component';
import {DatesPreviewDialogComponent} from './@core/utils/dialogs/dates-preview-dialog/dates-preview-dialog.component';
import {TimepickerDialogComponent} from './@core/utils/dialogs/timepicker-dialog/timepicker-dialog.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    ShowcaseDialogComponent,
    ConfirmDialogComponent,
    UpdateDatesDialogComponent,
    DatesPreviewDialogComponent,
    TimepickerDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NbDatepickerModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    NbDialogModule.forRoot(),
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbAccordionModule,
    UiSwitchModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
    AuthGuard,
    CanDeactivateGuard
  ],
  entryComponents: [ShowcaseDialogComponent, ConfirmDialogComponent, UpdateDatesDialogComponent, DatesPreviewDialogComponent, TimepickerDialogComponent]
})
export class AppModule {
}
