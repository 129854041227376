import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() content: string;
  @Input() btnYesText: string;
  @Input() btnCancelText: string;
  @Input() showDeployOption: boolean = false;
  private toggleRows: boolean = false;

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {
  }

  ngOnInit() {
  }

  onConfirm() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }

  onToggleRowsChange(isOn) {
    this.toggleRows = isOn;
  }

  get uploadNow() {
    return this.toggleRows;
  }
}
