<nb-card class="updateDatesBox" [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="large">
  <nb-card-header class="dialog-card"><h3>Import "{{title}}" dates</h3></nb-card-header>
  <nb-card-body>
    <div class="container">
      <form action="" [formGroup]="updateDatesForm">
        <div class="form-group">
          <div><label>Name *</label></div>
          <input nbInput type="text" placeholder="Keyword" formControlName="keyword" class="form-control">
          <div *ngIf="updateDatesForm.controls.keyword.errors" class="invalidField">
            <div *ngIf="updateDatesForm.controls.keyword.errors.required">Field is required</div>
          </div>
        </div>

        <div class="form-group">
          <div><label>City *</label></div>
          <input nbInput type="text" placeholder="City" formControlName="city" class="form-control">
          <div *ngIf="form.city.errors" class="invalidField">
            <div *ngIf="form.city.errors.required">Field is required</div>
          </div>
        </div>

        <div class="form-group">
          <div><label>Venue</label></div>
          <input nbInput type="text" placeholder="Venue (not supported in TicketMaster)" formControlName="venue"
                 class="form-control">
        </div>

        <div class="form-group">
          <div><label>Doesn't include</label></div>
          <input nbInput type="text" placeholder="Name doesn't include" formControlName="notInclude"
                 class="form-control">
        </div>

        <div class="form-group">
          <button nbButton ghost fullWidth shape="rectangle"
                  (click)="onConfirm(STUBHUB)" [disabled]="updateDatesForm.invalid">from StubHub</button>
        </div>

        <div class="form-group">
          <button nbButton ghost fullWidth shape="rectangle"
                  (click)="onConfirm(TICKET_MASTER)" [disabled]="updateDatesForm.invalid">from TicketMaster</button>
        </div>

        <div class="form-group">
          <button nbButton ghost fullWidth shape="rectangle"
                  outline (click)="onCancel()">Cancel</button>
        </div>

      </form>
    </div>
  </nb-card-body>
</nb-card>
