import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'ngx-timepicker-dialog',
  templateUrl: './timepicker-dialog.component.html',
  styleUrls: ['./timepicker-dialog.component.scss']
})
export class TimepickerDialogComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() defaultTime: string;
  @Input() btnConfirmText: string;
  @Input() btnCancelText: string;
  timePickerForm;
  @ViewChild('timeInputElement') timeInputElement: ElementRef;

  constructor(protected ref: NbDialogRef<TimepickerDialogComponent>) { }

  ngOnInit() {
    this.timePickerForm = new FormGroup({
      time: new FormControl(this.defaultTime)
    });
  }

  ngAfterViewInit(): void {
    this.timeInputElement.nativeElement.click();
  }

  onConfirm() {
    this.ref.close(this.timePickerForm.value);
  }

  onCancel() {
    this.ref.close(false);
  }

}
