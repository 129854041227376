<nb-card>
  <nb-card-header class="dialog-card">{{title}}</nb-card-header>
  <nb-card-body [innerHTML]="content">
  </nb-card-body>
  <nb-card-footer>
    <div id="updateToggleBox" class="row" *ngIf="showDeployOption">
      <div id="updateText" class="">Upload immediately <div class="rowsToggle">
        <i class="fas fa-times"></i>
        <ui-switch size="small" switchColor="white" (change)="onToggleRowsChange($event)"
                   [(ngModel)]="toggleRows"></ui-switch>
        <i class="fas fa-check"></i>
      </div></div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <button nbButton ghost fullWidth shape="rectangle" status="{{type}}"
                (click)="onConfirm()">{{btnYesText}}</button>
      </div>
      <div class="col-md-6">
        <button nbButton ghost fullWidth shape="rectangle" status="{{type}}"
                outline (click)="onCancel()">{{btnCancelText}}</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
