import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {API} from './API';
import { Observable } from 'rxjs';
import { UpdateDatesFormGroupInterface } from '../dialogs/update-dates-dialog/update-dates-dialog.component';

@Injectable()
export class CommunicationService {
  headers: any;
  adminDetails: string;

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    withCredentials: true
  };

  constructor(private http: HttpClient) {
  }

  public postHttp(data, type) {
    this.getUserToken();
    if (this.adminDetails) {
      data['adminDetails'] = this.adminDetails;
    }
    return this.http.post(API[type], data, this.httpOptions);
  }

  public postHttpNew<T>(apiUrl: string, data: any): Observable<T> {
    this.getUserToken();
    if (this.adminDetails) data.adminDetails = this.adminDetails;
    return this.http.post<T>(apiUrl, data, this.httpOptions);
  }

  public postHttpNewFileUpload(apiUrl: string, data: any, 
    httpOptions: any
    // {
    //   headers?: HttpHeaders | {
    //       [header: string]: string | string[];
    //   };
    //   observe: 'events';
    //   params?: HttpParams | {
    //       [param: string]: string | string[];
    //   };
    //   reportProgress?: boolean;
    //   responseType?: 'json';
    //   withCredentials?: boolean;
    // }
    ): Observable<HttpEvent<string[]>> {

    this.getUserToken();
    // if (this.adminDetails) data.adminDetails = this.adminDetails;
    apiUrl = apiUrl + '?adminDetails=' + this.adminDetails;
    return this.http.post<string[]>(apiUrl, data, httpOptions);
  }

  public post(data, apiUrl) {
    this.getUserToken();
    if (this.adminDetails) {
      data['adminDetails'] = this.adminDetails;
    }
    return this.http.post(apiUrl, data, this.httpOptions);
  }

  public putHttp(data, apiUrl) {
    this.getUserToken();
    if (this.adminDetails) {
      data['adminDetails'] = this.adminDetails;
    }
    return this.http.put(apiUrl, data);
  }

  public putHttpNew<T>(apiUrl: string, data: any): Observable<T> {
    this.getUserToken();
    if (this.adminDetails) data.adminDetails = this.adminDetails;
    return this.http.put<T>(apiUrl, data, this.httpOptions);
  }

  public getHttp(type, apiUrl: string = null) {
    this.getUserToken();
    const url = (apiUrl || API[type]) + (this.adminDetails && ('?adminDetails=' + this.adminDetails));
    return this.http.get(url, {withCredentials: true});
  }

  public getHttpNew<T>(apiUrl: string, params: any = {}): Observable<T> {
    this.getUserToken();
    params.adminDetails = this.adminDetails;
    return this.http.get<T>(apiUrl, {withCredentials: true, params: params});
  }

  public postNoAuth(data, type) {
    this.getUserToken();
    return this.http.post(API[type], data, this.httpOptions);
  }

  getUserToken() {
    const userData = localStorage.getItem('auth_app_token');
    if (userData) {
      this.adminDetails = JSON.parse(userData).value;
    } else {
      this.adminDetails = null;
    }
  }

  ticketMasterCall(data: UpdateDatesFormGroupInterface) {
    let url = API.TICKET_MASTER_API_URL;
    if (data.keyword) {
      url += `&keyword=${data.keyword}`;
    }
    if (data.city) {
      url += `&city=${data.city}`;
    }
    if (data.venue) {
      url += `&venue=${data.venue}`;
    }
    return this.http.get(url);
  }

  stubHubCall(data: UpdateDatesFormGroupInterface) {
    let url = API.STUB_API_URL;
    const headers = API.STUB_HEADERS;
    if (data.keyword) {
      url += `&q=${data.keyword}`;
    }
    if (data.city) {
      url += `&city=${data.city}`;
    }
    if (data.venue) {
      url += `&venue=${data.venue}`;
    }

    return this.http.get(url, {headers});
  }
}
