import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Copyright © 2022, <a href="https://shubert.nyc/" target="_blank">Shubert Organization</a></span>
  `,
})
export class FooterComponent {
}
