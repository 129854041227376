import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbSecurityModule, NbRoleProvider} from '@nebular/security';
import {SmartTableData} from './data/smart-table';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, StateService} from './utils';
import {UserData} from './data/users';
import {UserService} from './mock/users.service';
import {MockDataModule} from './mock/mock-data.module';
import {SmartTableService} from './mock/smart-table.service';
import {BASE_URL, FORGOT_PASSWORD_URL, LOGIN_URL} from './utils/communication/API';
import {RoleProvider} from './roles/role.provider';
import { UpdateDatesDialogComponent } from './utils/dialogs/update-dates-dialog/update-dates-dialog.component';
import { DatesPreviewDialogComponent } from './utils/dialogs/dates-preview-dialog/dates-preview-dialog.component';
import {NbCardModule} from '@nebular/theme';
import { TimepickerDialogComponent } from './utils/dialogs/timepicker-dialog/timepicker-dialog.component';

const DATA_SERVICES = [
  {provide: UserData, useClass: UserService},
  {provide: SmartTableData, useClass: SmartTableService}
];

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: BASE_URL,
        logout: {
          endpoint: '',
          redirect: {
            success: 'auth/login'
          },
        },
        login: {
          endpoint: LOGIN_URL,
        },
        requestPass: {
          endpoint: FORGOT_PASSWORD_URL,
        },

        token: {
          class: NbAuthJWTToken,
          key: 'adminDetails'
        }
      }),
    ],
    forms: {},
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      admin: {
        view: ['user', 'Dashboard', 'Events', 'Overview', 'In Process', 'History', 'Venues', 'Cities'],
        edit: '*',
        remove: '*'
      },
      voiceAdmin: {
        view: ['user', 'Dashboard', 'Events', 'Overview', 'In Process', 'History', 'Venues', 'Cities'],
        edit: '*',
        remove: '*'
      },
      review: {
        view: ['user', 'Dashboard', 'Events', 'Overview', 'In Process', 'History', 'Venues', 'Cities'],
        edit: '*',
        remove: '*'
      },
      client: {
        view: ['user', 'Venues', 'Events', 'Overview', 'History'],
        edit: '*',
        remove: '*'
      },
      superAdmin: {
        view: ['user', 'Dashboard', 'Events', 'Overview', 'In Process', 'History', 'Venues', 'Cities', 'Users'],
        edit: '*',
        remove: '*'
      },
      ultraSuperAdmin: {
        view: ['user', 'Dashboard', 'Events', 'Overview', 'In Process', 'History', 'Venues', 'Cities', 'Users'],
        edit: '*',
        remove: '*'
      }
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: RoleProvider,
  },
  AnalyticsService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
