import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommunicationService} from '../../communication/communication.service';
import {DatesShowService} from '../../services/dates-show.service';

export interface UpdateDatesFormGroupInterface {
  keyword: string;
  city: string;
  venue: string;
  notInclude: string;
}

@Component({
  selector: 'ngx-update-dates-dialog',
  templateUrl: './update-dates-dialog.component.html',
  styleUrls: ['./update-dates-dialog.component.scss']
})
export class UpdateDatesDialogComponent implements OnInit {
  @Input() title: string;
  @Input() city: string;
  updateDatesForm: FormGroup;
  STUBHUB = 'StubHub';
  TICKET_MASTER = 'TicketMaster';
  loading: boolean;

  constructor(protected ref: NbDialogRef<UpdateDatesDialogComponent>, private dateShowService: DatesShowService) {
  }

  ngOnInit() {
    this.updateDatesForm = new FormGroup(
      {
        keyword: new FormControl(this.title, Validators.required),
        city: new FormControl(this.city, Validators.required),
        venue: new FormControl(''),
        notInclude: new FormControl('')
      }
    );
  }

  get updateDatesFormValue(): UpdateDatesFormGroupInterface {
    return this.updateDatesForm.value as UpdateDatesFormGroupInterface;
  }

  onConfirm(url) {
      this.loading = true;
      this.dateShowService.updateDates(url, this.updateDatesFormValue).subscribe((response) => {
        if (response) {
          this.dateShowService.datesWereChanged.subscribe((res) => {
            if (res.isChanges) {
              this.ref.close(false);
            }
            this.loading = false;
          }, (error) => {
            console.log(error);
          });
        } else {
          this.loading = false;
        }
      }, (error) => {
        this.loading = false;
      });
  }

  get form() {
    return this.updateDatesForm.controls;
  }

  onCancel() {
    this.loading = false;
    this.ref.close(false);
  }

}
