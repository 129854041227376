<nb-card class="timePickerBox">
  <nb-card-header class="dialog-card"><h4>{{title}}</h4></nb-card-header>
  <nb-card-body>
    <div class="container">
      <form action="" [formGroup]="timePickerForm">
        <div class="form-group">
          <div class="timePickerToggle">
            <input #timeInputElement [ngxTimepicker]="toggleTimepicker" readonly formControlName="time" class="col-11">
            <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker #toggleTimepicker [defaultTime]="defaultTime"></ngx-material-timepicker>
          </div>
        </div>

        <div class="form-group">
          <button nbButton ghost fullWidth shape="rectangle" (click)="onConfirm()">{{btnConfirmText}}</button>
        </div>
        <div class="form-group">
          <button nbButton ghost fullWidth shape="rectangle" (click)="onCancel()">{{btnCancelText}}</button>
        </div>
      </form>
    </div>

  </nb-card-body>
<!--  <nb-card-footer>-->
<!--    <div class="form-group">-->
<!--      <button nbButton ghost fullWidth shape="rectangle" status="{{type}}">{{btnConfirmText}}</button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button nbButton ghost fullWidth shape="rectangle" status="{{type}}">{{btnCancelText}}</button>-->
<!--    </div>-->
<!--  </nb-card-footer>-->
</nb-card>
