<nb-card class="datesPreviewBox">
  <nb-card-header class="dialog-card">{{title}}
    <hr>
    <p>*please check before import</p>
  </nb-card-header>
  <nb-card-body id="textMsg" [innerHTML]="content">
  </nb-card-body>
  <nb-card-footer>
    <div class="form-group">
      <button nbButton ghost fullWidth shape="rectangle" status="{{type}}" (click)="onConfirm()">{{btnConfirmText}}</button>
    </div>
    <div>
      <button nbButton ghost fullWidth shape="rectangle" status="{{type}}" (click)="onCancel()">{{btnCancelText}}</button>
    </div>
  </nb-card-footer>
</nb-card>
