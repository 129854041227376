import {Component, Input, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserData} from '../../../@core/data/users';
import {AnalyticsService} from '../../../@core/utils';
import {NbAuthService, NbAuthJWTToken} from "@nebular/auth";
import {Router} from "@angular/router";


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: {};

  // userMenu = [{tag:'profileBtn',title: 'Profile'}, {title: 'Log out'}];
  userMenu = [{title: 'Log out', data: 'logoutBtn'}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private authService: NbAuthService,
              private router: Router,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload();
        }
      });

    this.menuService.onItemClick().subscribe(
      (data) => {
        if (data['item'] && data['item']['data'] === 'logoutBtn') {
          this.logout();
        }
      }
    )
  }

  logout() {
    this.authService.logout('email').subscribe(
      (data) => {
        this.router.navigate([data['redirect']]);
      }
    );
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
