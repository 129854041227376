import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {throwError} from 'rxjs';
import * as _ from 'lodash';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private router: Router,) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        if (_.has(error, 'status') && error.status === 403) {
          localStorage.removeItem('auth_app_token');
          this.router.navigate(['auth/login']);
          return throwError(error.error.message);
        }

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if (!!error.error && !!error.error.customErrorMessage) {
          return throwError({errorMessage: errorMessage, customErrorMessage: error.error.customErrorMessage});
        } else {
          return throwError(errorMessage);
        }
      })
    );
  }
}
