import {Injectable} from '@angular/core';
import {API} from './communication/API';
import {CommunicationService} from './communication/communication.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {

  selectedProduction: any;

  constructor(private communicationService: CommunicationService) {
  }

  // In the production-in-process component, production can be a union of Show & SubsChangesPopulated & AdminUser, but in other places it is just a Show
  setProduction(production, isOverview = false) {
    return new Promise((resolve, reject) => {
        this.selectedProduction = production;
        this.selectedProduction['isOverview'] = isOverview;
        this.checkIfShowInProcessing(resolve);
      }
    );
  }

  checkIfShowInProcessing(next) {
    const checkShowRequest = this.communicationService.getHttp(null, API.subsEditsGetById(this.selectedProduction._id)).subscribe(
      (res) => {
        if (res['error']) {
          this.selectedProduction['inReview'] = false;
          next();
        } else {
          this.selectedProduction['inReview'] = true;
          next();
        }
        checkShowRequest.unsubscribe();
      },
      (error) => {
        this.selectedProduction['inReview'] = false;
        next();
        checkShowRequest.unsubscribe();
      });
  }

  getProduction() {
    return this.selectedProduction;
  }

  getProductionTimeZone(): string {
    return this.selectedProduction.venue_id[0].timeZone || 'Utc';
  }
}
